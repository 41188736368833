import React, {Component} from "react";
import {Link} from "react-router-dom";
import {LanguageService} from "../language/LanguageService";
import {IfLoginInfo} from "models/IfLoginInfo";
import {langutil} from "language/langutil";
import clsx from "clsx";
import styled from "styled-components";
import {fontFamilyMedium} from "style/fonts";
import {ModalTrans2} from "ctls/modal/ModalTrans2";

interface Props {
  languageService: LanguageService;
  loginInfo?: IfLoginInfo;
  clickBookMark?: boolean;
  checkToastPopup?: boolean;
  type: string;
  seq?: any;
  msg?: string;
}

interface State {
  text: string;
  visible: boolean;
}

class ToastPopup extends Component<Props, State> {

  private timerId;

  constructor(props: Props) {
    super(props);
    this.state = {
      text: "",
      visible: false,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.seq != this.props.seq) {
      this.setState({visible: true});
      if (this.timerId) clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
        this.setState({visible: false});
        this.timerId = undefined;
      }, 3000);

      this.setState({visible: true});
    }
  }

  getTranslation(key: string): string {
    this.props.languageService.setNamespace("I18N_NAMESPACE_PRODUCT_PAGE");
    return this.props.languageService.getTranslationByKey(key) || "";
  }

  renderBookMarkToast(clear: boolean) {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    return <div>
      {clear ?
        <div className={clsx("bookmark-toast", scrollY !== scrollEnd ? "" : "")}>
          <div className={"bookmark-toast-text"}>
            {this.getTranslation('I18N_PRODUCT_DOC_BOOKMARK')}
          </div>
          <Link to={{pathname: `/${country}/${lang}/myPage`, search: 'menu=bookmark', state: {id: this.props.loginInfo.userId}}}>
            {this.getTranslation('I18N_PRODUCT_MYPAGE')}</Link>
        </div>
        :
        <div className={clsx("bookmark-toast", scrollY !== scrollEnd ? "" : "")}>
          <div className={"bookmark-toast-text"}>
            {this.getTranslation('I18N_PRODUCT_DOC_BOOKMARK_FAILED')}
          </div>
          <Link to={{pathname: `/${country}/${lang}/myPage`, search: 'menu=bookmark', state: {id: this.props.loginInfo.userId}}}>
            {this.getTranslation('I18N_PRODUCT_MYPAGE')}</Link>
        </div>
      }
    </div>;
  }

  renderShareToast() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    return <div>
      <div className={clsx("share-toast", scrollY !== scrollEnd ? "" : "")}>
        <div className={"share-toast-text"}>
          {this.getTranslation('I18N_URL_COPIED')}
        </div>
      </div>
    </div>;
  }

  renderSelectorShare() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    return <div>
      <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
        <div className={"bookmark-toast-text"}>
          {this.getTranslation('I18N_PRODUCT_SELECTOR_SHARE')}
        </div>
      </div>
    </div>;
  }

  renderSelectorCompareTab() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    return <div>
      <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
        <div className={"bookmark-toast-text"}>
          {this.getTranslation('I18N_PRODCUT_SELECTOR_COMPARE_TAB')}
        </div>
      </div>
    </div>;
  }

  renderSelectorCompareTabHmi() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    return <div>
      <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
        <div className={"bookmark-toast-text"}>
          {this.getTranslation('I18N_PRODCUT_SELECTOR_COMPARE_TAB_HMI')}
        </div>
      </div>
    </div>;
  }

  renderSelectorCompareTabVfd() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    return <div>
      <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
        <div className={"bookmark-toast-text"}>
          {this.getTranslation('I18N_PRODCUT_SELECTOR_COMPARE_TAB_VFD')}
        </div>
      </div>
    </div>;
  }

  renderSelectorAddedTab() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    return <div>
      <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
        <div className={"bookmark-toast-text"}>
          {this.getTranslation('I18N_PRODCUT_SELECTOR_ADDED_TAB')}
        </div>
      </div>
    </div>;
  }

  renderUnknown() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    return <div>
      <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
        <div className={"bookmark-toast-text"}>
          {this.getTranslation('I18N_PRODCUT_UNKNOWN_USER')}
        </div>
      </div>
    </div>;
  }

  renderMsg() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    return <div>
      <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
        <div className={"bookmark-toast-text"}>
          {this.props.msg}
        </div>
      </div>
    </div>;
  }

  renderSelectorReset() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    return <div>
      <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
        <div className={"bookmark-toast-text"}>
          {this.getTranslation('I18N_PRODUCT_SELECTOR_RESET')}
        </div>
      </div>
    </div>;
  }

  renderSelectorAdd(clear: boolean) {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    return <div>
      {clear ?
        <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
          <div className={"bookmark-toast-text"}>
            {this.getTranslation('I18N_PRODUCT_SELECTOR_ADD')}
          </div>
        </div>
        :
        <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
          <div className={"bookmark-toast-text"}>
            {this.getTranslation('I18N_PRODUCT_SELECTOR_ADD_CLEAR')}
          </div>
        </div>
      }
    </div>;
  }

  renderSelectorAddOption() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    return <div>
      <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
        <div className={"bookmark-toast-text"}>
          {this.getTranslation('I18N_PRODUCT_SELECTOR_ADD')}
        </div>
      </div>

    </div>;
  }

  renderSelectorCompare(clear: boolean) {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;

    const {country, lang} = langutil.splitLangCode(this.props.languageService.lang());
    return <div>
      {clear ?
        <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
          <div className={"bookmark-toast-text"}>
            {this.getTranslation('I18N_PRODUCT_SELECTOR_COMPARE')}
          </div>
        </div>
        :
        <div className={clsx("selectorAdd", scrollY !== scrollEnd ? "" : "")}>
          <div className={"bookmark-toast-text"}>
            {this.getTranslation('I18N_PRODUCT_SELECTOR_COMPARE_CLEAR')}
          </div>
        </div>
      }
    </div>;
  }

  renderFeedbackToast() {
    const scrollY = window.scrollY;
    const scrollEnd = document.body.scrollHeight - window.innerHeight;


    return <div>
      <div className={clsx("feedback-toast", scrollY !== scrollEnd ? "" : "")}>
        <div className={"feedback-toast-text"}>
          {this.getTranslation('I18N_FEEDBACK_ALERT')}
        </div>
      </div>
    </div>;
  }

  renderToast(type: string) {
    switch (type) {
      case "feedback":
        return this.renderFeedbackToast();

      case "bookmark":
        return this.renderBookMarkToast(false);

      case "bookmark-clear":
        return this.renderBookMarkToast(true);

      case "share":
        return this.renderShareToast();

      case "selectorAdd":
        return this.renderSelectorAdd(true);

      case "selectorAdd-clear":
        return this.renderSelectorAdd(false);

      case "selectorCompare":
        return this.renderSelectorCompare(true);

      case "selectorCompare-clear":
        return this.renderSelectorCompare(false);

      case 'selectorShare':
        return this.renderSelectorShare();
      case 'selectorAddOption':
        return this.renderSelectorAddOption();

      case 'selectorCompareTab':
        return this.renderSelectorCompareTab();
      case 'selectorCompareTabHmi':
        return this.renderSelectorCompareTabHmi();
      case 'selectorCompareTabVfd':
        return this.renderSelectorCompareTabVfd();
      case 'selectorAddedTab':
        return this.renderSelectorAddedTab();
      case 'selectorReset':
        return this.renderSelectorReset();
      case 'unknown':
        return this.renderUnknown();
      case 'msg':
        return this.renderMsg();
    }
  }

  render() {
    return <>{this.state.visible &&
      <ModalTrans2>
        <Toast onClick={(e) => {
          this.setState({visible: false});
          if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
          }
        }}>
          {this.renderToast(this.props.type)}
        </Toast>
      </ModalTrans2>
    }
    </>;
  }
}

export default ToastPopup;


const Toast = styled.div`
  .bookmark-toast {
    position: fixed;
    left: calc(50% + 60.8rem);
    bottom: 3rem;
    width: 320px;
    height: 6rem;
    z-index: 98;
    background: #2A2C2F;
    border-radius: 4px;
    line-height: 6rem;
    padding: 0 2.4rem;
    display: flex;
    justify-content: space-between;

    .bookmark-toast-text {
      color: white;
      ${fontFamilyMedium};
      font-size: 1.4rem;
    }

    a {
      color: #407DD8;
      ${fontFamilyMedium};
      font-size: 1.4rem;
    }
  }

  .selectorAdd {
    position: fixed;
    left: calc(50% + 60.8rem);
    bottom: 3rem;
    width: 320px;
    height: 6rem;
    z-index: 10002;
    background: #2A2C2F;
    border-radius: 4px;
    line-height: 6rem;
    padding: 0 2.4rem;
    display: flex;
    justify-content: space-between;

    .bookmark-toast-text {
      color: white;
      ${fontFamilyMedium};
      font-size: 1.4rem;
    }

    a {
      color: #407DD8;
      ${fontFamilyMedium};
      font-size: 1.4rem;
    }
  }

  .bookmark-toast.no0 {
    bottom: 3rem;
  }

  .bookmark-toast.no1 {
    bottom: 15rem;
  }


  .bookmark-toast.no2 {
    bottom: 25rem;
  }

  .bookmark-toast.on {
    position: fixed;
    left: calc(50% + 60.8rem);
    bottom: calc(25%);
    width: 320px;
    height: 6rem;
    z-index: 98;
    background: #2A2C2F;
    border-radius: 4px;
    line-height: 6rem;
    padding: 0 2.4rem;
    display: flex;
    justify-content: space-between;
  }

  .selectorAdd.on {
    position: fixed;
    left: calc(50% + 60.8rem);
    bottom: calc(26%);
    width: 320px;
    height: 6rem;
    z-index: 10002;
    background: #2A2C2F;
    border-radius: 4px;
    line-height: 6rem;
    padding: 0 2.4rem;
    display: flex;
    justify-content: space-between;
  }

  .share-toast {
    position: fixed;
    left: calc(50% + 60.8rem);
    bottom: 3rem;
    width: 320px;
    height: 6rem;
    z-index: 98;
    background: #2A2C2F;
    border-radius: 4px;
    line-height: 6rem;
    padding: 0 2.4rem;
    display: flex;
    justify-content: space-between;

    .share-toast-text {
      color: white;
      ${fontFamilyMedium};
      font-size: 1.4rem;
    }

    a {
      color: #407DD8;
      ${fontFamilyMedium};
      font-size: 1.4rem;
    }
  }

  .feedback-toast {
    position: fixed;
    left: calc(50% + 60.8rem);
    bottom: 3rem;
    width: 320px;
    height: 6rem;
    z-index: 98;
    background: #2A2C2F;
    border-radius: 4px;
    line-height: 6rem;
    padding: 0 2.4rem;
    display: flex;
    justify-content: space-between;

    .feedback-toast-text {
      color: white;
      ${fontFamilyMedium};
      font-size: 1.4rem;
    }
  }


  .share-toast.on {
    position: fixed;
    left: calc(50% + 60.8rem);
    bottom: calc(25%);
    width: 320px;
    height: 6rem;
    z-index: 98;
    background: #2A2C2F;
    border-radius: 4px;
    line-height: 6rem;
    padding: 0 2.4rem;
    display: flex;
    justify-content: space-between;
  }


  @media screen and (min-width: 765px) {
    .bookmark-toast {
      left: inherit;
      right: 2rem;
    }

    .bookmark-toast.on {
      left: inherit;
      right: 2rem;
    }

    .selectorAdd {
      left: inherit;
      right: 2rem;
    }

    .selectorAdd.on {
      left: inherit;
      right: 2rem;
    }

    .share-toast {
      left: inherit;
      right: 2rem;
    }

    .feedback-toast {
      left: inherit;
      right: 2rem;
    }

    .share-toast.on {
      left: inherit;
      right: 2rem;
    }


  }
  @media screen and (max-width: 764px) {
    .bookmark-toast {
      left: 0;
      right: 0;
      margin: auto;
    }

    .bookmark-toast.on {
      left: 0;
      right: 0;
      margin: auto;
    }

    .selectorAdd {
      left: 0;
      right: 0;
      margin: auto;
      bottom: 6rem;
    }

    .selectorAdd.on {
      left: 0;
      right: 0;
      margin: auto;
    }

    .share-toast {
      left: 0;
      right: 0;
      margin: auto;
    }

    .share-toast.on {
      left: 0;
      right: 0;
      margin: auto;
    }

    .feedback-toast {
      left: 0;
      right: 0;
      margin: auto;
    }

  }

`;