import {resi18nutil} from "language/resi18nutil";
import {resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";

const resi18n_main_page = {
  I18N_MAIN_TITLE: ["에너지 절감 시뮬레이터", "Energy Saving Simulator", undefined],
  I18N_MAIN_DESCRIPTION: [
    "LS ELECTRIC Drive 제품을 사용하여 에너지 요금을 절감해 보세요.<br/>에너지 절감량을 확인하고 PDF로 리포트를 다운받을 수 있습니다.",
    "Reduce your energy bills with LS ELECTRIC Drive products.<br>Check your energy savings and download a report as a PDF file."
    , undefined],
  I18N_MAIN_BUTTON: ["내 에너지 절감량 계산해 보기", "Calculate My Energy Savings", undefined],
  I18N_MAIN_MOBILE_DESCRIPTION: [
    "LS ELECTRIC Drive 제품을 사용하여 에너지 요금을 절감해 보세요.",
    "Check your energy savings and download a report as a PDF file."
    , undefined],

  I18N_MAIN_DESKTOP_COMMUNITY_TITLE: [
    "<span>커뮤니티</span>에서 전문가와 소통해 보세요.",
    "Enhancing your engineering skills<br/>through <span>collective knowledge</span>"
    , undefined],
  I18N_MAIN_DESKTOP_COMMUNITY_DESCRIPTION: [
    "커뮤니티에는 Q&A / 블로그 / 자유게시판 / 구인구직 / 사고팔기 등 <br> 다양한 공간이 마련되어 있습니다. 자동화분야 전문가들과 다양한 의견을 교환해 보세요.",
    "Ask questions, dive into discussions, and get inspired."
    , undefined],
  I18N_MAIN_MOBILE_COMMUNITY_TITLE: [
    "<span>커뮤니티</span>에서 소통해 보세요.",
    "Enhancing your engineering skills<br/>through <span>collective knowledge.</span>"
    , undefined],
  I18N_MAIN_MOBILE_COMMUNITY_DESCRIPTION: [
    "커뮤니티에는 Q&A / 블로그 / 자유게시판 / 구인구직 / 사고팔기 등 다양한 공간이 마련되어 있습니다. 자동화분야 전문가들과 다양한 의견을 교환해 보세요.",
    "Ask questions, dive into discussions, and get inspired."
    , undefined],

  I18N_MAIN_DESKTOP_PRODUCT_SELECTOR_TITLE: ["제품 선정 가이드", "Product Selector", undefined],
  I18N_MAIN_DESKTOP_PRODUCT_SELECTOR_DESCRIPTION: ["이제 LS ELECTRIC의 다양한 LV Drive, HMI 제품을 선정할 수 있습니다. <br/ > 전체 제품 스펙의 필터 및 검색, 모델별 상세 스펙을 비교 기능을 지원합니다. <br />최종 선정된 제품을 담아 수량과 함께 공유해 보세요.",
    "You can now select various LV Drive and HMI products from LS ELECTRIC <br />Filter and search of all product specifications and compare details for each model. <br /> Add your final products and share it with URL. "
    , undefined],
  I18N_MAIN_DESKTOP_PRODUCT_SELECTOR_DESCRIPTION_MOBILE: ["이제 LS ELECTRIC의 다양한 LV Drive, HMI 제품을 선정할 수 있습니다. 전체 제품 스펙의 필터 및 검색, 모델별 상세 스펙을 비교 기능을 지원합니다.",
    "You can now select various LV Drive and HMI products. Filter all product specifications and compare details for each model."
    , undefined],
  I18N_MAIN_DESKTOP_PRODUCT_SELECTOR_DRIVE: ['LV Drive 제품 선정 가이드', "Product Selector for LV Drive", undefined],
  I18N_MAIN_DESKTOP_PRODUCT_SELECTOR_HMI: ['HMI 제품 선정 가이드', "Product Selector for HMI", undefined],

  I18N_MAIN_BAND_TITLE: ["제품 기술 자료", "Products", undefined],
  I18N_MAIN_BAND_DESCRIPTION: [
    "자료가 필요한 제품 또는 제품군을 선택하세요. 사용 설명서, 기술 문서 등 다양한 자료가 검색됩니다.",
    "Browse technical documentation organized by product."
    , undefined],
  I18N_MAIN_MOBILE_BAND_DESCRIPTION: [
    "사용 설명서, 기술 문서 등 다양한 자료가 검색됩니다.",
    "Download documents, support information, software and video content."
    , undefined],
  I18N_MAIN_BAND_INDUSTRY_TITLE: ["분야별 적용 사례", "Solutions", undefined],
  I18N_MAIN_BAND_INDUSTRY_DESCRIPTION: [
    "분야를 선택하면 관련 제품과 적용 사례를 보실 수 있습니다.",
    "Browse industry-specific references and related contents."
    , undefined],
  I18N_MAIN_SEARCH_PLACEHOLDER: ["검색어를 입력하세요.", "Type keywords.", undefined],
  I18N_MAIN_SEARCH_RESULT_RELATION: ["연관 검색어", "Related Suggestion", undefined],
  I18N_MAIN_SEARCH_RELATION: ["연관", "Related", undefined],
  I18N_MAIN_SEARCH_RESULT_CATEGORY: ["제품 카테고리", "Product Category", undefined],
  I18N_MAIN_SEARCH_RESULT_MODEL: ["모델", "Model", undefined],
  I18N_MAIN_SEARCH: ["검색하기", "Search", undefined],
  I18N_MAIN_SEARCH_INCLUDE_CHECKBOX: ["문서 내 검색 포함", "Attachment Contains", undefined],
  I18N_MAIN_SEARCH_RECENT_SEARCH: ["최근 검색어", "Recent searches", undefined],
  I18N_MAIN_DOWNLOAD_TITLE: [
    "XG5000과 XP-Builder에서<br />Sample 및 S/W 최신 버전을 다운 받아 보세요.",
    "By connecting the engineering software with Solution Square, <br/> users can experience a wide range of services."
    , undefined],
  I18N_MAIN_DOWNLOAD_DESCRIPTION: [
    "Solution Square는 클라우드상에서 다양한 무료 Sample, Library, Template을 제공하고,<br /> 최신 버전 업데이트 기능을 지원합니다.",
    "Solution Square (SSQ) offers a wide range of sample code, graphics libraries, and data files that can be downloaded quickly and easily directly from software such as XG5000, XP-Builder, and Drive-CM."
    , undefined],
  I18N_MAIN_DOWNLOAD_INFO: [
    "<p>Sample 다운로드</p><p>[도구] > [Library / Sample]</p><p>최신 버전 업데이트</p><p>[도움말] > [최신 버전 확인]</p>",
    "<p>Sample Download</p><p>[Tool] > [Library Sample]</p><p>Check the latest version </p><p>[Help] > [Check for Update]</p>"
    , undefined],
  I18N_MAIN_DOWNLOAD_SAMPLE: ["샘플 라이브러리 바로가기", "Go to Sample Library", undefined],
  I18N_MAIN_TECH_TITLE: ["기술 지원 서비스", "Support & Services", undefined],
  I18N_MAIN_TECH_DESCRIPTION: ["LS ELECTRIC 자동화 솔루션의 다양한 기술 지원 서비스를 받아보세요.", "Access to on-site experience and expertise.", undefined],
  I18N_MAIN_MOBILE_TECH_DESCRIPTION: ["다양한 기술 지원 서비스를 받아보세요.", "Get more technical support services.", undefined],
  I18N_MAIN_TECH_ONLINE_CLASS: ["동영상 교육", "Homepage", undefined],
  I18N_MAIN_TECH_ONLINE_CLASS_DESC: ["Youtube 바로가기", "Go to LS ELECTRIC Website.", undefined],
  I18N_MAIN_TECH_OFFLINE_CLASS: ["오프라인 교육 신청", "FAQ", undefined],
  I18N_MAIN_TECH_OFFLINE_CLASS_DESC: ["체계적인 교육 서비스", "Go to FAQ board", undefined],
  I18N_MAIN_TECH_BRANCH: ["영업 지사", "Company", undefined],
  I18N_MAIN_TECH_BRANCH_DESC: ["제품 구입 문의처", "Discover about us.", undefined],
  I18N_MAIN_TECH_SERVICE_CENTER: ["서비스 센터", "Where to buy", undefined],
  I18N_MAIN_TECH_SERVICE_CENTER_DESC: ["제품 서비스 지정점 안내", "Find our distributors.", undefined],
  I18N_MAIN_TECH_CUSTOMER_CENTER: ["기술 상담 센터", "Online Service", undefined],
  I18N_MAIN_TECH_CUSTOMER_CENTER_DESC: ["평일 9~17시 (점심 제외)", "Weekdays, 9:00 to 17:00", undefined],
  I18N_MAIN_TECH_INQUIRY: ["온라인 고객 문의", "For Distributor", undefined],
  I18N_MAIN_TECH_INQUIRY_DESC: ["제품 정보, 기술 상담 문의", "Collaboration partnership.", undefined],
  I18N_MAIN_TECH_ONLINE_SERVICE: ["온라인 서비스 신청", "Gearbox Size Manger", undefined],
  I18N_MAIN_TECH_ONLINE_SERVICE_DESC: ["구입 제품 서비스 신청", "Go to gearbox selection tool.", undefined],
  I18N_MAIN_TECH_REPORT: ["성적서 신청", "Product Check", undefined],
  I18N_MAIN_TECH_REPORT_DESC: ["온라인 발급 서비스", "Find a serial number.", undefined],
  I18N_MAIN_SEARCH_IN_RESULT: ["결과 내 재검색", "Search within results.", undefined],
  I18N_MAIN_TIP: ["이용안내", "Help", undefined],
  I18N_MAIN_COMMUNITY_TITLE: ["커뮤니티", "Community", undefined],
  I18N_MAIN_COMMUNITY_DESC: [
    "커뮤니티에서 전문가들과 다양한 방식으로 소통해 보세요.",
    "Knowledge-Sharing for your expertise."
    , undefined],
  I18N_MAIN_COMMUNITY_QNA_TITLE: ["Q&A", "Q&A", undefined],
  I18N_MAIN_COMMUNITY_QNA_DESC: [
    "자동화에 관한 질문을 하거나 <br /> 답변을 해보세요.",
    "Be a problem solver."
    , undefined],
  I18N_MAIN_COMMUNITY_BLOG_TITLE: ["Knowledge Base", "Knowledge Base", undefined],
  I18N_MAIN_COMMUNITY_BLOG_DESC: [
    "기술을 공유하고, <br /> 다양한 지식을 쌓아 보세요.",
    "Knowledge-Sharing for your expertise."
    , undefined],
  I18N_MAIN_COMMUNITY_BOARD_TITLE: ["게시판", "Bulletin Board", undefined],
  I18N_MAIN_COMMUNITY_BOARD_DESC: [
    "자유게시판 / 사고팔기 / 구인구직 등 <br /> 정보를 공유하세요.",
    "Everything you need."
    , undefined],
  I18N_MAIN_COMMUNITY_SAMPLE_TITLE: ["샘플 라이브러리", "Sample Library", undefined],
  I18N_MAIN_COMMUNITY_SAMPLE_DESC: ["샘플을 업로드하고, 소프트웨어에서  <br /> 간편하게 다운로드 해보세요.", "Access to a variety of sample codes and projects.", undefined],
  I18N_MAIN_SQUARE: ["Tech Square는 다양한 업종의 스마트공장 구축을 필요로하는 수요기업과 구축에 최적화된 공급기업을 매칭해주는 서비스 입니다.", null, undefined],
  I18N_MAIN_SQUARE_BUTTON: ["매칭 서비스 받으러 가기", null, undefined],
  I18N_MAIN_MOBILE_SQUARE: ["다양한 업종의 스마트공장 구축을 필요로하는 수요기업과 구축에 최적화된 공급기업을 매칭해주는 서비스 입니다.", null, undefined],
  I18N_MAIN_SEARCH_SPELLER: ["로 검색한 결과입니다.", "Search results for", undefined],
  I18N_MAIN_SEARCH_SPELLER_VIEW: ["검색결과 보기", "Display search results", undefined],
};

const [ko, en, enus] = resi18nutil.getres(resi18n_main_page);

export const resi18n_main_page_ko = {...resi18n_all_ko, ...ko};
export const resi18n_main_page_en = {...resi18n_all_en, ...en};
export const resi18n_main_page_enus = {...resi18n_all_enus, ...enus};
