import {resi18nutil} from "language/resi18nutil";
import {resi18n_all_en, resi18n_all_enus, resi18n_all_ko} from "language/resi18n_all";

const resi18n_service_page = {
  I18N_TITLE_MAIN: ["메인", "Main", undefined],
  I18N_TITLE_COMMUNITY: ["커뮤니티", "Community", undefined],
  I18N_TITLE_SERVICE: ['서비스', 'Service', undefined],
  I18N_TITLE_POST: ['글쓰기', 'Write', undefined],
  I18N_SOLUTION_EMPLOYEE: ['임직원', "Employee", undefined],
  I18N_SOLUTION_EMPLOYEE_ONLY: ["임직원 전용", "Employee Only", undefined],
  I18N_DOC_VIEW_MORE: ['더보기', 'View More', undefined],
  I18N_TITLE_STRING: ['샘플을 업로드하고, 소프트웨어에서 편하게 다운로드 해보세요.',
    'Download and share sample code and projects for faster engineering time and more effective programming.', undefined],
  I18N_SERVICE_FILTER_SAMPLE_DOC_TYPE: ['샘플 종류', "Sample type", undefined],
  I18N_SERVICE_FILTER_SOFTWARE_TYPE: ['소프트웨어 종류', 'Software type ', undefined],
  I18N_SERVICE_FILTER_XG5000: ['XG5000', 'XG5000', undefined],
  I18N_SERVICE_FILTER_XP_BUILDER: ['XP-Builder', 'XP-Builder', undefined],
  I18N_SERVICE_FILTER_DRIVEVIEW: ['DriveView', "DriveView", undefined],
  I18N_SERVICE_FILTER_TYPE: ['유형', 'Type', undefined],
  I18N_SERVICE_FILTER_SAMPLE: ["샘플", "Sample", undefined],
  I18N_SERVICE_FILTER_LIBRARY: ['라이브러리', 'Library', undefined],
  I18N_SERVICE_FILTER_TEMPLATE: ['템플릿', 'Template', undefined],
  I18N_SERVICE_FILTER_ALL: ['전체', "All", undefined],

  I18N_NO_DOCUMENT: ["조회된 자료가 없습니다.", "No result found.", undefined],
  I18N_NO_DOCUMENT_NOTICE: ["조회 필터를 설정해 주세요.", "Choose different filters.", undefined],
  I18N_SERVICE_SEARCH_RESET: ["검색 옵션 초기화", 'Clear Filter', undefined],
  I18N_SERVICE_TALK_IDEA_TITLE: ["페이지에 대한 의견을 말씀해주세요.", "Please leave your feedbacks.", undefined],
  I18N_SERVICE_HELPFUL: ["도움돼요", "Helpful", undefined],
  I18N_SERVICE_NOT_HELPFUL: ["부족해요", "Not Helpful", undefined],
  I18N_SERVICE_FEEDBACK_PLACEHOLDER: ["피드백을 남겨주세요.", "Tell us more...", undefined],
  I18N_SERVICE_FEEDBACK_BUTTON: ["전송", "Send", undefined],
  I18N_SERVICE_MODIFY: ["수정", "Modify", undefined],
  I18N_SERVICE_DELETE: ["삭제", "Delete", undefined],
  I18N_SERVICE_CANCEL: ["취소", "Cancel", undefined],
  I18N_SERVICE_COMMENT_REPORT: ["신고", "Report", undefined],
  I18N_SERVICE_COMMENT_MODIFY_CONFIRM: ["수정을 취소하시겠습니까?", "Cancel this modification?", undefined],
  I18N_SERVICE_COMMENT_PLACEHOLDER: ["댓글 입력", "Add a comment", undefined],
  I18N_SERVICE_COMMENT_MODIFY_PLACEHOLDER: ["댓글 수정", "Modify comment", undefined],
  I18N_SERVICE_NO_COMMENT: ["댓글 내용이 없습니다.", "No replies.", undefined],
  I18N_PRODUCT_CATEGORY: ["제품 카테고리", "Product Category", undefined],
  I18N_INDUSTRY_CATEGORY: ["산업 카테고리", "Industry Category", undefined],
  I18N_APPLICATION_LOAD_CATEGORY: ["적용 부하 카테고리", "Application & Load Category", undefined],
  I18N_SERVICE_CONFIRM_DELETE: ["삭제 하시겠습니까?", "Do you want to delete?", undefined],
  I18N_SERVICE_CONFIRM_DELETE_SUCCESS: ["포스트가 삭제되었습니다.", "The post has been removed.", undefined],
  I18N_SERVICE_ERROR_DELETE: ["삭제에 실패하였습니다. 다시 시도해주세요.", "Fail to delete. Try again.", undefined],
  I18N_SERVICE_COMPLETE_DELETE: ["삭제가 완료되었습니다.", "Delete completed.", undefined],
  I18N_FILTER_KOREA: ["대한민국", "Korea", undefined],
  I18N_FILTER_GLOBAL: ["Global", "Global", undefined],
  I18N_FILTER_USA: ["USA", "USA", undefined],

  I18N_SOLUTION_DOWNLOAD: ["다운로드", "Download", undefined],
  I18N_DOC_COL_VERSION: ["버전 ", "Version ", undefined],
  I18N_FILE_LANG_KO: ["한국어", "Korean", undefined],
  I18N_FILE_LANG_EN: ["영어", "English", undefined],
  I18N_FILE_LANG_ETC: ["기타", "Etc.", undefined],
  I18N_DOC_DOWNLOAD_COUNT: ["다운로드수", "Download", undefined],
  I18N_SERVICE_FEEDBACK_ALERT: ["피드백이 전송되었습니다.", "Feedback is submitted.", undefined],

  I18N_SERVICE_SAMPLE_HELP_STRING1: ['샘플 라이브러리 이용 방법', 'How to use Sample Library', undefined],
  I18N_SERVICE_SAMPLE_HELP_STRING2: ['샘플을 설명과 함께 업로드', 'Upload a sample with its explanation', undefined],
  I18N_SERVICE_SAMPLE_HELP_STRING3: ['(업로드시 다운받을 소프트웨어 선택)', '(Select download software)', undefined],
  I18N_SERVICE_SAMPLE_HELP_STRING4: ['XG5000, XP-Builder, DriveView S/W에서 다운로드', 'Download from XG5000, XP-Builder, DriveView S/W', undefined],
  I18N_SERVICE_SAMPLE_HELP_STRING5: ['([도구] > [라이브러리 / 샘플])', '([Tool]-[Library/Sample])', undefined],


  I18N_SERVICE_LECTURE_NOTHING: ["예정된 강의가 없습니다.", "There are no scheduled courses.", undefined],
  I18N_FILTER_SITE_LANGUAGE: ["등록 사이트", "Registration sit", undefined],
  I18N_FILTER_KOREAN: ["한국어", "Korean", undefined],
  I18N_FILTER_ENGLISH: ["영어", "English", undefined],
  I18N_FILTER_ENGLISH_US: ["영어(US)", "English(US)", undefined],
  I18N_SERVICE_LECTURE_ADD: ["강의 등록", "Course Registration", undefined],
  I18N_SERVICE_LECTURE_MODIFY: ["강의 설정", "Course Setting", undefined],
  I18N_SERVICE_LECTURE_ALERT_ADD_LECTURE: ["강의 등록이 완료되었습니다.", "Course registration completed.", undefined],
  I18N_SERVICE_LECTURE_ALERT_MODIFY_LECTURE: ["강의 설정이 완료되었습니다.", "Course setting completed.", undefined],

  I18N_SERVICE_LECTURE_AT_LEAST_TEN: ["10자 이하 텍스트", "Max. 10 text", undefined],
  I18N_SERVICE_LECTURE_AT_LEAST_THIRTY: ["30자 이하 텍스트", "Max. 30 text", undefined],
  I18N_SERVICE_LECTURE_AT_LEAST_TREEE_HUNDRED: ["300자 이하 텍스트", "Max. 300 text", undefined],
  I18N_SERVICE_LECTURE_URL: ["강의실 URL", "Class room URL", undefined],
  I18N_SERVICE_LECTURE_IS_PRIVATE: ["암호로 입장하기", "Enter with private password", undefined],
  I18N_SERVICE_LECTURE_INTRODUCE: ["강의 내용", "Contents", undefined],

  I18N_SERVICE_LECTURE_ADD_GUIDE_TITLE: ["이용 안내", "Usage Guidelines", undefined],

  I18N_SERVICE_LECTURE_EVENT_POPUP_TITLE: ["금일 예정된 강의가 있사오니, 많은 참여 바랍니다.", "We have a lecture scheduled for today, please join us.", undefined],
  I18N_SERVICE_LECTURE_EVENT_POPUP_BUTTON: ["실시간 강의실 바로가기", "Go to Online Training", undefined],
  I18N_SERVICE_LECTURE_EVENT_POPUP_SKIP: ["오늘 하루 보지 않기", "Do not display for today", undefined],

  I18N_POPUP_DISABLE_DAY: ['오늘 하루 보지 않기', "Don't show again today", undefined],
  I18N_POPUP_DISABLE_WEEK: ['일주일 보지 않기', "Don't show again week", undefined],
  I18N_POPUP_DISABLE_NEVER: ['다시 보지 않기', "Don't show again", undefined],

  I18N_SERVICE_LECTURE_NAME: ["강의명", "Course Name", undefined],
  I18N_SERVICE_LECTURE_NAME_POPUP: ["강의명", "Course Name", undefined],

  I18N_SERVICE_LECTURE_MAIL_TITLE: ["[LS ELECTRIC] Solution Square - 실시간 강의 알림", "[LS ELECTRIC] Solution Square – Online Training Notification", undefined],
  I18N_SERVICE_LECTURE_MAIL_ONE_DAY: ["실시간 강의가 24시간 후 시작됩니다.", "Online Training will start in 24 hours.", undefined],
  I18N_SERVICE_LECTURE_MAIL_ONE_DAY_STRING: ["많은 참여 바랍니다.", "Please participate a lot.", undefined],

  I18N_SERVICE_LECTURE_MAIL_THIRTY_MINUTES: ["실시간 강의가 30분 후에 시작됩니다.", "Online Training will start in 30 minutes.", undefined],
  I18N_SERVICE_LECTURE_MAIL_THIRTY_MINUTES_STRING: ["링크로 이동하신 후 강의실에 접속해 주세요.", "Go to the link and access the classroom.", undefined],

  I18N_SERVICE_LECTURE_MAIL_BUTTON: ["실시간 강의실 바로가기", "Go to Online Training", undefined],
  I18N_SERVICE_LECTURE_MAIL_TEMP: ["해당 메일은 실시간 강의실 알림 설정시 발송됩니다.", "This mail is sent when you enable the notification setup.", undefined],

  I18N_SERVICE_LECTURE_SAVE_BUTTON: ["저장", "Save", undefined],

  I18N_SERVICE_LECTURE_ADD_PUBLIC_TITLE: ["공개 강의실 개설", "Create a public room", undefined],
  I18N_SERVICE_LECTURE_ADD_PUBLIC_STRING1: ["1. 아웃룩 > 일정 > 홈 > 새 Teams 모임 클릭", "1. Outlook > Home > New Teams Meeting", undefined],
  I18N_SERVICE_LECTURE_ADD_PUBLIC_STRING2: ["2. 생성된 강의실 링크를 복사하여 ‘강의실 URL’에 입력", "2. Copy the created link and paste to ‘Lecture Room URL’", undefined],

  I18N_SERVICE_LECTURE_ADD_PRIVATE_TITLE: ["암호 입장 방식 강의실 개설", "Create a password type room", undefined],
  I18N_SERVICE_LECTURE_ADD_PRIVATE_STRING1: ["1. ‘암호로 입장하기’를 체크합니다. (URL 자동입력)", "1. Check ‘Enter with private password’ (URL automatically fills).", undefined],
  I18N_SERVICE_LECTURE_ADD_PRIVATE_STRING2: ["2. 링크 하단 ‘모임ID, 암호’를 참석자에게 전달합니다.", "2. Send ‘Meeting ID, P/W’ below the link to the participants.", undefined],

  I18N_SERVICE_LECTURE_INSERT_ALERT_LINE: ["필수 항목 입니다.", "This field is required.", undefined],
  I18N_SERVICE_LECTURE_REQUIRED: ["필수 입력값", "Required fields", undefined],
  I18N_SERVICE_LECTURE_INSERT_ALERT_LINE_LECTURENM: ["30자 이하 텍스트를 입력해 주세요.", "Enter Max. 30 characters.", undefined],
  I18N_SERVICE_LECTURE_INSERT_ALERT_LINE_INSTRUCTOR: ["10자 이하 텍스트를 입력해 주세요.", "Enter Max. 10 characters.", undefined],
  I18N_SERVICE_LECTURE_INSERT_ALERT_LINE_CONTENTS: ["300자 이하 텍스트를 입력해 주세요.", "Enter Max. 300 characters.", undefined],

  I18N_SERVICE_LECTURE_GUIDE_TITLE: ["이용 안내", "Usage Guidelines", undefined],
  I18N_SERVICE_LECTURE_GUIDE_STRING1: ["강의 시간 30분 전에 활성화되는 ‘입장하기’ 버튼을 클릭하여 강의실로 이동합니다.", 'Click the "Enter" button to move to the training room.', undefined],
  I18N_SERVICE_LECTURE_GUIDE_STRING2: ["마이크 / 비디오 설정을 마치고, 이름을 입력한 후 ‘지금 참가’ 버튼을 클릭하여 입장합니다.", "Complete the microphone/video settings, enter your name, and click the 'Join Now' button to enter.", undefined],
  I18N_SERVICE_LECTURE_GUIDE_STRING3: ["비공개 강의일 경우, 사전 제공된 ‘모임ID’와 ‘암호’를 이용하여 입장하여 주세요.", "If it is a private lecture, please enter using the 'meeting ID' and 'password' provided in advance.", undefined],

  I18N_SERVICE_LECTURE_EXPOSE: ["공개", "Public", undefined],
  I18N_SERVICE_LECTURE_NOT_EXPOSE: ["비공개", "Private", undefined],
  I18N_SERVICE_LECTURE_PASSWORD: ["암호", "Password", undefined],

  I18N_SERVICE_LECTURE_ENTRY: ["입장하기", "Join Now", undefined],
  I18N_SERVICE_LECTURE_TEACHER: ["강사", "Instructor", undefined],
  I18N_SERVICE_LECTURE_DATE: ["일시", "Date", undefined],
  I18N_SERVICE_LECTURE_INTRO: ["내용", "Content", undefined],
  I18N_SERVICE_LECTURE_ALT: ["알림 설정시 강의 시작 하루 전, 30분 전에 알림 메일이 발송됩니다.", "Notification emails will be sent a day before the training course, and 30 minutes before.", undefined],
  I18N_SERVICE_LECTURE_ALERT: ["강의 알림이 설정되었습니다. 강의시작 \n하루 전, 30분 전에 알림 메일이 발송됩니다.", "Notifications have been set. Notification emails will be sent \na day before the training course, and 30 minutes before.", undefined],
  I18N_COMMUNITY_MODIFY: ["수정", "Modify", undefined],
  I18N_COMMUNITY_DELETE: ["삭제", "Delete", undefined],
  I18N_CLASSROOM_DAYS_LEFT: ["일 전", "days before", undefined],

  I18N_ASIDE_GRID: ["개별 선택", "Multiple", undefined],
  I18N_ASIDE_SLIDER: ["범위 선택", "Range", undefined],

  I18N_TITLE_SERVICE_CLASSROOM: ["실시간 강의실", "Online Training", undefined],
  I18N_TITLE_SERVICE_CLASSROOM_STRING1: ["실시간 무료 강의를 수강하실 수 있습니다.", "You can join online training courses.", undefined],
  I18N_TITLE_SERVICE_CLASSROOM_STRING2: ["강의 시간에 접속하여 강의를 수강해 보세요.", "Access a training course by entering the active lecture.", undefined],

  I18N_SERVICE_LECTURE_FOOTER_GUIDE_TITLE: ["정기 교육 신청", "Request a regular course", undefined],
  I18N_SERVICE_LECTURE_FOOTER_GUIDE_STRING: ["다양한 오프라인 정기 교육도 진행 되오니 많은 이용 바랍니다.", "Various offline training courses are also conducted, so please participate a lot.", undefined],

  I18N_TITLE_SERVICE_REMOTE: ["원격 지원", "Remote Technical Support", undefined],
  I18N_TITLE_SERVICE_REMOTE_STRING: ['원격 지원 서비스는 기술지원시 제공되는 서비스 입니다.', 'Remote Service is only for technical support purpose.', undefined],
  I18N_TITLE_SERVICE_REMOTE_ADVICE_STRING1: ['상담원 대기', 'Agent is waiting.', undefined],
  I18N_TITLE_SERVICE_REMOTE_ADVICE_STRING2: ['안내 받은 ‘접속코드’를 입력하여 접속해 주시기 바랍니다. (설치 필요)', 'Please enter the "access code" you were informed to access. (Installation Required)', undefined],
  I18N_TITLE_SERVICE_REMOTE_FEATURES: ["이용 안내", "Features", undefined],
  I18N_TITLE_SERVICE_REMOTE_ADVICE_STRING3: ["기종에 맞는 ‘이지모바일’ 앱을 설치해 주세요.", 'Please install the app called "Easy Mobile" according to your model.', undefined],
  I18N_TITLE_SERVICE_REMOTE_ADVICE_STRING4: ["제공된 ‘접속코드’를 입력하여 접속합니다.", 'Enter the provided "access code" to access.', undefined],
  I18N_TITLE_SERVICE_REMOTE_ADVICE_STRING5: ["자동 다운로드 되는 소프트웨어를 설치한 후 실행합니다.", "Install and run the software that is automatically downloaded.", undefined],
  I18N_TITLE_SERVICE_REMOTE_GOOGLE_PLAY_STORE: ['Google Play Store', 'Google Play Store', undefined],
  I18N_TITLE_SERVICE_REMOTE_APPLE_STORE: ['Apple Store', "Apple Store", undefined],
  I18N_TITLE_SERVICE_REMOTE_ETC: ["LG, 샤오미 등 기타 기종", 'Other models such as LG and Xiaomi', undefined],

  I18N_SERVICE_PRODUCT_SELECTOR_EMPTY_NOTICE01: ['선택한 사양과 일치하는 제품이 없습니다.', 'There are no models to recommend.', undefined],
  I18N_SERVICE_PRODUCT_SELECTOR_EMPTY_NOTICE02: ['다른 사양을 선택해 주세요.', 'Select an option from the filter', undefined],

  I18N_SERVICE_PRODUCT_SELECTOR_FIRST_NOTICE01: ['필터에서 사양을 선택해 주세요.', 'Please select the filter options.', undefined],
  I18N_SERVICE_PRODUCT_SELECTOR_FIRST_NOTICE02: ['선택한 사양과 일치하는 제품을 추천합니다.', "We'll recommend products that match.", undefined],

  I18N_TITLE_PRODUCT_SELECTOR: ["제품 선정 가이드", "Product Selector", undefined],
  I18N_TITLE_ENERGY_SAVE: ["에너지 절감 시뮬레이터", "Energy Saving Simulator", undefined],
  I18N_PRODUCT_SELECTOR_RESET: ['초기화', "Reset", undefined],
  I18N_PRODUCT_SELECTOR_RECOMMEND: ['추천', 'Recommend', undefined],
  I18N_PRODUCT_SELECTOR_COMPARE: ['비교', 'Compare', undefined],
  I18N_PRODUCT_SELECTOR_ADDED: ['담기', 'Added', undefined],
  I18N_PRODUCT_SELECTOR_ADD: ['담기', 'Add', undefined],
  I18N_PRODUCT_SELECTOR_ADDED2: ['담음', 'Added', undefined],
  I18N_PRODUCT_SELECTOR_SEARCH_KEYWORD: ['키워드 검색', 'Search Keywords', undefined],
  I18N_PRODUCT_SELECTOR_MODEL: ['모델', 'Model', undefined],
  I18N_PRODUCT_SELECTOR_MODEL_NAME: ['모델명', 'Model Name', undefined],
  I18N_PRODUCT_SELECTOR_DESCRIPTION: ['설명', 'Description', undefined],
  I18N_PRODUCT_SELECTOR_DOC: ['문서', 'Doc', undefined],
  I18N_PRODUCT_SELECTOR_OPTION: ['옵션', 'Option', undefined],
  I18N_PRODUCT_SELECTOR_NOTICE: ['주의 사항 : 이 추천 정보는 특정 용도에 대한 제품의 적합성이나 신뢰성을 보장하지 않습니다.',
    'Notice: This information does not guarantee the suitability or reliability of the product for a specific use.', undefined],

  I18N_PRODUCT_SELECTOR_TOAST_START_VALUE_ALERT: ['최솟값보다 큰 값을 입력해 주세요.', 'Check the input value.', undefined],
  I18N_PRODUCT_SELECTOR_TOAST_END_VALUE_ALERT: ['최댓값보다 작은 값을 입력해 주세요.', 'Check the input value.', undefined],

  I18N_PRODUCT_SELECTOR_SHOW_DIFFER: ['차이점 강조 표시', "Show Differences", undefined],
  I18N_PRODUCT_SELECTOR_QUANTITY: ['수량', 'Quantity', undefined],
  I18N_PRODUCT_SELECTOR_MOTOR_QUANTITY: ['모터 수량', 'Motor quantity', undefined],
  I18N_PRODUCT_SELECTOR_TYPE: ['구분', "Type", undefined],
  I18N_PRODUCT_SELECTOR_LV_DRIVE_OPTION: ['LV Drive 옵션', "LV Drive Option", undefined],
  I18N_PRODUCT_SELECTOR_HMI_OPTION: ['HMI 옵션', 'HMI Option', undefined],
  I18N_PRODUCT_SELECTOR_SELECT: ['선택', 'Select', undefined],
  I18N_PRODUCT_SELECTOR_RESET_OPTIONS: ['검색 옵션 초기화', 'Reset options', undefined],
  I18N_PRODUCT_SELECTOR_OVERLOAD_HELP1: ['(컨베이어, 엘리베이터, 인쇄기 등)', '(conveyors, elevators, printing presses, etc.)', undefined],
  I18N_PRODUCT_SELECTOR_OVERLOAD_HELP2: ['(팬, 펌프, 블로어  등)', '(fans, pumps, blowers, etc.)', undefined],

  I18N_PRODUCT_SELECTOR_RECOMMENDED_MODEL: ['추천 제품', 'Recommended Model', undefined],
  I18N_PRODUCT_SELECTOR_OTHER_MANUFACTURERS: ['제조사 모델 비교', 'Other Manufacturers', undefined],
  I18N_PRODUCT_SELECTOR_OTHER_MANUFACTURERS_HELP1: ['제조사별 LV Drive에 해당하는 LS ELECTRIC 제품을 추천합니다.', 'Cross reference LV Drives from other manufacturers to LS ELECTRIC’s.', undefined],
  I18N_PRODUCT_SELECTOR_OTHER_MANUFACTURERS_HELP2: ['해당 기능은 참고용입니다. 자세한 내용은 각 제조사에 문의 하시길 바랍니다.', 'This content is for reference only. Contact the manufacturer for detailed specifications.', undefined],

  I18N_PRODUCT_SELECTOR_ASIDE_POWER: ['전력', 'Power', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_INPUT_VOLTAGE: ['전압', 'Input Voltage', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_COMMUNICATION: ['통신', 'Communication', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_CERTIFICATE: ['인증', 'Certificate', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_PROTECTION: ['보호', 'Protection', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_OPTION: ['옵션', 'Option', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_MODEL: ['시리즈', 'Model', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_OTHER_MANUFACTURERS: ['타사 시리즈 비교', 'Other Manufacturers', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_RECOMMENDED: ['추천 시리즈', 'Recommended', undefined],

  I18N_PRODUCT_SELECTOR_ASIDE_OS: ['운영체제', 'OS', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_INPUT_POWER: ['입력 전압', 'Input Power', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_PORT: ['포트', 'Port', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_EXTERNAL_MEMORY: ['메모리', 'External Memory', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_OTHERS: ['기타', 'Others', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_SCREEN_SIZE: ['화면 크기 (inch)', 'Screen Size (inch)', undefined],
  I18N_PRODUCT_SELECTOR_ASIDE_SOFTWARE_TYPE: ['소프트웨어 보기', 'Software Type', undefined],

  I18N_PRODUCT_SELECTOR_PDF_PRODUCT_LIST: ['제품 목록', 'Product List', undefined],
  I18N_PRODUCT_SELECTOR_PDF_PRODUCT_TOTAL_QUANTITY: ['합계', 'Total Quantity', undefined],
  I18N_PRODUCT_SELECTOR_PDF_DOWNLOAD: ['PDF 다운로드', 'PDF Download', undefined],

  I18N_TITLE_SW_SERVICE: ['SW 솔루션', 'Software Solution', undefined],
  I18N_TITLE_SW_SERVICE_INFO: ['생산관리, 데이터백업,공장 모니터링 등 Smart Factory 관련 다양한 SW 솔루션을 만나보세요.',
    'Discover SW solutions related to Smart Factory such as MES, data backup, monitoring, etc.', undefined],

  I18N_PRODUCT_SELECTOR_OR_SEARCH: ['OR 검색', 'Search by OR conditions', undefined],
  I18N_PRODUCT_SELECTOR_AND_SEARCH: ['AND 검색', 'Search by AND conditions', undefined],

  I18N_PARTNER: ["파트너 전용", "Partner Only", undefined],

  I18N_CONTACT_TITLE: ["고객지원", "Contact", undefined],
  I18N_CONTACT_INPUT_INFORMATION: ["로그인시 자동 입력됩니다.", "This will be auto-filled when you log in.", undefined],
  I18N_CONTACT_MANDATORY_INPUT: ["필수 입력값", "Required fields", undefined],
  I18N_CONTACT_EMAIL: ["메일 주소", "E-mail", undefined],
  I18N_CONTACT_EMAIL_PLACEHOLDER: ["ex) abc@company.com", "ex) abc@company.com", undefined],
  I18N_CONTACT_FIRST_NAME: ["이름", "First name", undefined],
  I18N_CONTACT_LAST_NAME: ["성", "Last name", undefined],
  I18N_CONTACT_NAME: ["성명", "Name", undefined],
  I18N_CONTACT_NAME_PLACEHOLDER: ["20자 이하 텍스트", "Max. 20 characters", undefined],
  I18N_CONTACT_PHONE_NUMBER: ["전화 번호", "Phone Number", undefined],
  I18N_CONTACT_PHONE_NUMBER_PLACEHOLDER: ["숫자만", "Only number", undefined],
  I18N_CONTACT_COMPANY: ["회사", "Company", undefined],
  I18N_CONTACT_COMPANY_PLACEHOLDER: ["ex) LS ELECTRIC", "ex) LS ELECTRIC", undefined],
  I18N_CONTACT_REGION: ["지역", "Location", undefined],
  I18N_CONTACT_REGION_PLACEHOLDER: ["ex) 경기도 안양시", "ex) Roma, Italy", undefined],
  I18N_CONTACT_MESSAGE: ["메시지", "Message", undefined],
  I18N_CONTACT_MESSAGE_PLACEHOLDER: ["50자 이내 텍스트", "Max. 50 characters", undefined],
  I18N_CONTACT_SUBMIT: ["보내기", "Send", undefined],

  // energy save
  I18N_ENERGY_SELECT_VALUE: ["선택", "Select", undefined],
  I18N_ENERGY_INPUT_VALUE: ["입력", "Input", undefined],
  I18N_ENERGY_INPUT_INDUSTRY: ["산업용", "Industry", undefined],
  I18N_ENERGY_INPUT_ENTERPRISE: ["상업용", "Commercial", undefined],
  I18N_ENERGY_INPUT_FARM: ["농업용", "Agriculture", undefined],
  I18N_ENERGY_INPUT_INVALID_ALERT: ["올바른 값을 기입하세요.", "Please enter the correct value.", undefined],
  I18N_ENERGY_INPUT_MOTORTYPE: ["모터 타입", "Motor Type", undefined],
  I18N_ENERGY_INPUT_APPLICATION_INFO: ["어플리케이션 정보", "Application Information", undefined],
  I18N_ENERGY_INPUT_WIND_VOLUME: ["풍량", "Air Volume", undefined],
  I18N_ENERGY_INPUT_FLUX_VOLUME: ["유량", "Flow Rate", undefined],
  I18N_ENERGY_INPUT_ANSWER_CONTROL_TYPE: ["제어 방식 입력하기", "Select flow control method", undefined],
  I18N_ENERGY_INPUT_MOTOR_VOLUME: ["모터 용량", "Motor Power", undefined],
  I18N_ENERGY_INPUT_VOLUME: ["용량", "Power", undefined],
  I18N_ENERGY_INPUT_MOTOR_VOLUME_INPUT: ["모터 용량 입력하기", "Enter the power rating of the motor", undefined],
  I18N_ENERGY_INPUT_MOTOR_VOLUME_ELECTRIC: ["전류", "Current", undefined],
  I18N_ENERGY_INPUT_MOTOR_VOLUME_VOLTAGE: ["정격 전압", "Input Voltage", undefined],
  I18N_ENERGY_INPUT_EFFICIENCY: ["효율", "Efficiency", undefined],
  I18N_ENERGY_INPUT_EFFICIENCY_SELECT: ["등급으로 선택하기", "Select the motor efficiency class", undefined],
  I18N_ENERGY_INPUT_MOTOR_QUANTITY: ["모터 수량", "Number of Motors", undefined],
  I18N_ENERGY_INPUT_MOTOR_QUANTITY_EA: ["대", "EA", undefined],
  I18N_ENERGY_INPUT_OPERATE_INFO: ["운영 정보", "Operational Information", undefined],
  I18N_ENERGY_INPUT_GET_MOTORTYPE: ["타입1 가져오기", "Copy Type1", undefined],
  I18N_ENERGY_INPUT_USE_HOUR: ["하루 중 사용 시간", "Usage (per day)", undefined],
  I18N_ENERGY_INPUT_USE_HOUR2: ["하루 중 사용 시간", "Usage per day", undefined],
  I18N_ENERGY_INPUT_HOUR: ["시간", "Hours", undefined],
  I18N_ENERGY_INPUT_USE_DAY: ["월간 운영일 수", "Usage (per month)", undefined],
  I18N_ENERGY_INPUT_USE_DAY2: ["월간 운영일 수", "Usage per month", undefined],
  I18N_ENERGY_INPUT_DAY: ["일", "Days", undefined],
  I18N_ENERGY_INPUT_FEE: ["전기요금", "Electricity Rates", undefined],
  I18N_ENERGY_INPUT_FEE_SELECT: ["전기요금 선택하기", "Select type of electricity rate", undefined],
  I18N_ENERGY_INPUT_DELETE: ["모터 타입 삭제", "Delete Motor Type", undefined],

  I18N_ENERGY_HISTORY_TITLE: ["문의 내역", "Inquiry Details", undefined],
  I18N_ENERGY_HISTORY_KEYWORD: ["키워드", "Keyword", undefined],
  I18N_ENERGY_HISTORY_KEYWORD_PLACEHOLDER: ["이메일 / 내용", "E-mail / Content", undefined],
  I18N_ENERGY_HISTORY_DATE: ["기간", "Period", undefined],
  I18N_ENERGY_HISTORY_TYPE: ['구분', "Type", undefined],
  I18N_ENERGY_HISTORY_TYPE_TOTAL: ['전체', "All", undefined],
  I18N_ENERGY_HISTORY_TYPE_CONTACT: ['컨설팅', "Contact", undefined],
  I18N_ENERGY_HISTORY_EMAIL: ['이메일', "E-mail", undefined],
  I18N_ENERGY_HISTORY_LINK: ['자세히 보기', "View Details", undefined],
  I18N_ENERGY_HISTORY_SITE: ['사이트', "Site", undefined],
  I18N_ENERGY_HISTORY_WRITE_DATE: ['문의 일자', "Inquiry Date", undefined],

  I18N_ENERGY_SETTING_TITLE: ["관리자 설정", "Admin Settings", undefined],
  I18N_ENERGY_SETTING_INPUT_INFORMATION: ["전기 요금 및 이메일 수신인을 설정할 수 있습니다.", "Set the electricity rate and e-mail recipients.", undefined],
  I18N_ENERGY_SETTING_RATE: ["전기 요금", "Electricity Rate", undefined],
  I18N_ENERGY_SETTING_WON: ["원", "won", undefined],
  I18N_ENERGY_SETTING_KOR: ["대한민국", "Korea", undefined],
  I18N_ENERGY_SETTING_EMAIL: ["이메일 알림 수신", "E-mail Recipients", undefined],
  I18N_ENERGY_SETTING_EMAIL_REMARK: ["‘ , ’로 구분", "Separated by ' , '", undefined],
  I18N_ENERGY_SETTING_INPUT_TYPE: ["구분", "Type", undefined],
  I18N_ENERGY_SETTING_INPUT_PLACEHOLDER: ["숫자만", "Only number", undefined],
  I18N_ENERGY_SETTING_SUBMIT: ["저장", "Save", undefined],

  I18N_ENERGY_SETTING_SUCCESS: ['설정 완료되었습니다.', "Setting completed.", undefined],

  I18N_ENERGY_MAIN_DOWNLOAD_COUNT: ["PDF", "PDF", undefined],
  I18N_ENERGY_MAIN_SHARE_COUNT: ["공유", "Share", undefined],
  I18N_ENERGY_MAIN_CONTACT_COUNT: ["컨설팅", "Consultation", undefined],
  I18N_ENERGY_MAIN_TWO_WEEKS: ["2주 내 새 문의", "New Inquiries(2 weeks)", undefined],
  I18N_ENERGY_MAIN_OPEN_HISTORY: ["문의 내용 확인", "View Inquiries", undefined],
  I18N_ENERGY_MAIN_SELECT_APPLICATION: ["어플리케이션 선택", "Application Type", undefined],
  I18N_ENERGY_MAIN_ADD_MOTOR_TYPE: ["모터 타입 추가", "Add Motor Type", undefined],
  I18N_ENERGY_MAIN_PDF: ["PDF 저장", "Save as PDF", undefined],
  I18N_ENERGY_MAIN_SHARE: ["공유", "Share", undefined],
  I18N_ENERGY_MAIN_CONTACT: ["무료 컨설팅", "Contact Our Experts", undefined],
  I18N_ENERGY_MAIN_RECOMMEND: ["추천 모델", "Recommended Models", undefined],
  I18N_ENERGY_MAIN_RECOMMEND_INPUT_MESSAGE: ["정보를 입력하면, 적합한 모델을 추천해 드립니다.<br/>제품선정가이드에서 더 많은 제품을 확인해 보세요.", "After you enter your information, you'll see recommended models.<br/>See more products in product selector.", undefined],
  I18N_ENERGY_MAIN_RECOMMEND_EMPTY_MESSAGE: ["조건을 만족하는 모델이 없습니다.<br/>제품선정가이드에서 더 많은 제품을 확인해 보세요.", "There are no recommended models.<br/>See more products in product selector.", undefined],
  I18N_ENERGY_PRODUCT_SELECTOR: ["제품 선정 가이드 바로가기", "Go to Product Selector", undefined],
  I18N_ENERGY_MAIN_RECOMMEND_MESSAGE: ["위 데이터는 일반적인 조건으로 산정합니다. 사용 환경, 스펙에 따라 결과가 달라질 수 있으니 무료 컨설팅를 통해 정확한 견적을 알아보세요.", "Results may vary depending on the usage environment and specifications, so contact our experts to get an accurate estimate.", undefined],

  I18N_ENERGY_CHART_DAY: ["일간", "Daily", undefined],
  I18N_ENERGY_CHART_MONTH: ["월간", "Monthly", undefined],
  I18N_ENERGY_CHART_YEAR: ["연간", "Yearly", undefined],


  I18N_ENERGY_REPORT_DAY: ["일간", "Daily", undefined],
  I18N_ENERGY_REPORT_MONTH: ["월간", "Monthly", undefined],
  I18N_ENERGY_REPORT_YEAR: ["연간", "Annual", undefined],
  I18N_ENERGY_CHART_ANSWER_MOTOR_TYPE: ["정보를 입력하면, 분석정보가 보입니다.", "After you enter your information, you'll see your analytics.", undefined],

  I18N_ENERGY_RESULT_POWER_CHANGED: ["전력량 변화", "Change in Power Consumption", undefined],
  I18N_ENERGY_RESULT_FEE_CHANGED: ["전기 요금 변화", " Change in Electricity Rates", undefined],
  I18N_ENERGY_RESULT_BEFORE: ["도입 전", "With Existing System", undefined],
  I18N_ENERGY_RESULT_AFTER: ["도입 후", "With LS ELECTRIC Drive", undefined],
  I18N_ENERGY_RESULT_SAVING_COST: ["비용 절감량", "Cost Savings", undefined],
  I18N_ENERGY_RESULT_SAVING: ["에너지 절감량", "Energy Savings", undefined],
  I18N_ENERGY_RESULT_CO2: ["CO2 감소량", "CO2 Reduction", undefined],
  I18N_ENREGY_RESULT_MONEY_KWH: ["₩/kWh", "$/kWh", undefined],
  I18N_ENREGY_RESULT_MONEY: ["₩", "$", undefined],

  I18N_ENERGY_TOAST_ANSWER_MOTORTYPE: ['모터 타입 정보를 입력해주세요.', 'Please enter the motor type.', undefined],
  I18N_ENERGY_TOAST_RESOLVE_CONTACT: ['문의가 정상적으로 전송되었습니다.', 'Your inquiry has been sent.', undefined],
  I18N_ENERGY_TOAST_SHARE: ['Link를 클립보드에 복사하였습니다.', 'Link copied to clipboard.', undefined],
  I18N_ENERGY_EMAIL_INVALID_ALERT: ["메일 주소 형식을 확인해 주세요.", "Please enter a valid email address", undefined],
  I18N_ENERGY_PARAM_RESET: ['검색 옵션이 초기화 되었습니다.', 'All filters have been cleared.', undefined],

  I18N_ENERGY_REPORT_LEGEND_AFTER_TYPE: ['도입 후 (타입', 'Drive (Type', undefined],
  I18N_ENERGY_REPORT_LEGEND_AFTER: ['도입 후', 'Drive', undefined],
  I18N_ENERGY_REPORT_LEGEND_BEFORE_TYPE: ['도입 전 (타입', 'Before (Type', undefined],
  I18N_ENERGY_REPORT_LEGEND_BEFORE: ['도입 전', 'Before', undefined],
  I18N_ENERGY_REPORT_TITLE: ['에너지 절감 리포트', 'Energy Savings Report', undefined],

  I18N_ENERGY_REPORT_SUB_TITLE: ['일반적인 조건으로 산정한 데이터입니다. 사용 환경, 스펙에 따라 결과가 달라질 수 있으니 무료 컨설팅를 통해 정확한 견적을 알아보세요.', 'Your results may vary depending on your environment and specs, so get a free consultation to get an accurate estimate.', undefined],
  I18N_ENERGY_REPORT_POWER_CHANGED_YEAR: ['연간 전력량 변화', 'Change in Power Consumption'],
  I18N_ENERGY_REPORT_FEE_CHANGED_YEAR: ['연간 전기 요금 변화', 'Change in Electricity Rates'],
  I18N_ENERGY_REPORT_BEFORE: ['도입 전', 'Existing System'],
  I18N_ENERGY_REPORT_AFTER: ['도입 후', 'LS ELECTRIC'],
  I18N_ENERGY_REPORT_RESULT_POWER: ['전력량', 'Power'],
  I18N_ENERGY_REPORT_RESULT_SAVING: ['절감액', 'Reduction Rate'],
  I18N_ENERGY_REPORT_INPUT: ['입력 내용', 'Input Settings'],
  I18N_ENERGY_REPORT_INPUT_MOTORTYPE: ['모터', 'Motor Type'],
  I18N_ENERGY_REPORT_VOLUME: ['용량', 'Motor Power'],

  I18N_ENERGY_CONTACT_AMPARE: ["전류", "Ampare", undefined],
  I18N_ENERGY_CONTACT_VOLTAGE: ["전압", "Voltage", undefined],
  I18N_ENERGY_CONTACT_INPUT: ["입력", "Input", undefined],
  I18N_ENERGY_CONTACT_MOTOR: ["모터", "Motor", undefined],

  I18N_ENERGY_REPORT_PRINT: ["프린트", "Print", undefined],
  I18N_ENERGY_REPORT_DOWNLOAD: ["다운로드", "Download", undefined],

  I18N_ASSET_INSIGHT_HELP_BTN: ["도움말 보기", "Help Guide", undefined],
  I18N_ASSET_INSIGHT_SEC01_TEXT: ["Asset Insight는 Drive의 데이터를 모니터링하고,<br/> 운전 분석으로 예지 보전하는 솔루션 입니다.", "Asset Insight is a solution that monitors data from your drives and provides predictive maintenance through drive analytics.", undefined],
  I18N_ASSET_INSIGHT_SEC01_BTN: ["시작하기", "Get Started", undefined],
  I18N_ASSET_INSIGHT_SEC02_TITLE: ["차세대 클라우드<br/> 관제", "Cloud<br/>  Monitoring", undefined],
  I18N_ASSET_INSIGHT_SEC02_KEYWORD01: ["통합 모니터링", "Monitoring", undefined],
  I18N_ASSET_INSIGHT_SEC02_KEYWORD02: ["Drive 건강도 분석", "Health Analytics", undefined],
  I18N_ASSET_INSIGHT_SEC02_KEYWORD03: ["에너지 절감량", "Energy Savings", undefined],
  I18N_ASSET_INSIGHT_SEC02_KEYWORD04: ["이상 상태 알람 메시지", "Alarm Messages", undefined],
  I18N_ASSET_INSIGHT_SEC03_TITLE: ["원격 제어 및<br/> 파라미터 이력 관리", "Remote Control<br/> & Parameter<br/> Management", undefined],
  I18N_ASSET_INSIGHT_SEC03_KEYWORD01: ["모바일 지원", "Mobile Support", undefined],
  I18N_ASSET_INSIGHT_SEC03_KEYWORD02: ["원격 제어", "Remote Control", undefined],
  I18N_ASSET_INSIGHT_SEC03_KEYWORD03: ["파라미터 저장", "Save Parameters", undefined],
  I18N_ASSET_INSIGHT_SEC03_KEYWORD04: ["기기 공유", "Share Devices ", undefined],
  I18N_ASSET_INSIGHT_SEC04_TITLE: ["이용 안내", "How to Start", undefined],
  I18N_ASSET_INSIGHT_SEC04_GUIDE01: ["S300 Drive에 이더넷 옵션 카드를 장착한 후 Drive Web Dashboard에 접속합니다.", "Insert an EthernetIP Option Card into the S300 Drive, access the Web Dashboard.", undefined],
  I18N_ASSET_INSIGHT_SEC04_GUIDE02: ["소유자 전화번호를 입력하고, Ethernet/IP 통신설정에서 ‘클라우드 접근 및 쓰기’ 를 허용합니다.", "Enter the owner's phone number and allow \"Cloud access, writing\" in the setting page.", undefined],
  I18N_ASSET_INSIGHT_SEC04_GUIDE03: ["Drive Web Dashboard에서 제공하는 QR코드를 이용해 모바일로 기기를 등록합니다.", "Register your device on mobile using the QR code provided in the Web Dashboard.", undefined],
  I18N_ASSET_INSIGHT_SEC04_GUIDE04: ["기기를 인터넷에 연결한 후 Solution Square에 접근하면 등록된 기기를 볼 수 있습니다.", "After you connect your device to the internet, you can view it in Solution Square.", undefined],
  I18N_ASSET_INSIGHT_SEC04_GUIDE_REMARK: ["일부 기종은 지원하지 않습니다. 구입 전 지원 불가 모델을 확인하세요.", "Not all devices are supported; check unsupported models before purchasing.", undefined],

};

const [ko, en, enus] = resi18nutil.getres(resi18n_service_page);

export const resi18n_service_page_ko = {...resi18n_all_ko, ...ko};
export const resi18n_service_page_en = {...resi18n_all_en, ...en};
export const resi18n_service_page_enus = {...resi18n_all_enus, ...enus};
