import {fontFamilyBold, fontFamilyMedium, fontFamilyRegular} from "asset/style/AssetFonts";
import styled, {createGlobalStyle} from "styled-components";
import {AssetImages} from "asset/images/AssetImages";
import * as colors from "asset/style/color";

export const AssetStyle = createGlobalStyle`
  html {
    color: #1A1D24;

    * {
      letter-spacing: 0;
    }
  }

  a {
    text-decoration: none;
    outline: none;
    color: inherit;

    &:hover, &:active {
      text-decoration: none;
      color: inherit;
    }
  }

  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;

    &-thumb {
      background-color: rgba(141, 141, 141, 0.7);
      border-radius: 26px;
      background-clip: padding-box;
      border: 3px solid transparent;
    }

    &-track {
      background-color: transparent;
    }

    &-button {
      width: 0;
      height: 0;
    }
  }

  @keyframes slideright {
    from {
      transform: scaleX(0);
    }

    to {
      transform: scaleX(100%);
    }
  }

  .mobile {
    display: none;
  }

  span.state_mark {
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 12px;
    border-radius: 5px;
    ${fontFamilyBold};
    font-size: 1.5rem;

    &.normal {
      background: rgba(68, 194, 87, 0.1);
      color: ${colors.normal};

      &:after {
        display: inline-block;
        content: 'Normal';
      }
    }

    &.fault {
      background: rgba(243, 0, 0, 0.1);
      color: ${colors.fault};

      &:after {
        display: inline-block;
        content: 'Fault';
      }
    }

    &.warning {
      background: rgba(255, 199, 0, 0.1);
      color: ${colors.warning};

      &:after {
        display: inline-block;
        content: 'Warning';
      }
    }

    &.disconnected {
      background: rgba(141, 141, 141, 0.1);
      color: rgba(141, 141, 141, 0.7);

      &:after {
        display: inline-block;
        content: 'Disconnected';
      }
    }
  }

  .badge {
    display: inline-block;
    background: ${colors.accent01};
    color: #fff;
    font-size: 1.4rem;
    padding: 0 4px;
    border-radius: 60px;
    min-width: 30px;
    height: 24px;
    line-height: 24px;
    margin-left: 5px;
    ${fontFamilyBold}
  }

  @media screen and (max-width: 1199px) {

    html {
      font-size: 10px !important;
    }

    .non_mobile {
      display: none;
    }

    .mobile {
      display: block;
    }

    span.state_mark {
      height: 22px;
      line-height: 22px;
    }
  }
`;

export const MorePop = styled.div`
  width: 92px;
  border: solid 1px ${colors.divider06};
  background: #fff;
  position: absolute;
  box-shadow: 3px 4px 4px 0px rgba(0, 0, 0, 0.3);
  display: none;
  z-index: 100;
  right: 10px;
  top: 13px;

  &.on {
    display: block;
  }

  p {
    height: 38px;
    line-height: 38px;
    text-align: center;
    font-size: 1.4rem;
    color: ${colors.text03};
    cursor: pointer;

    &:hover {
      background: #E2EBFB;
      color: ${colors.accent01};
      font-weight: 600;
    }
  }
`;

export const AssetOverlay = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99;

  &.tran-overlay {
    background: transparent;
  }

  @media screen and (max-width: 1199px) {
    background: rgba(0, 0, 0, 0.5);
    left: 0 !important;
  }

`;
export const AssetModalWrap = styled.div`
  background: ${colors.bg05};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  box-shadow: 6px 8px 4px rgba(11, 24, 62, 0.2);
  outline: solid 1px ${colors.divider06};
  width: 605px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 97% !important;;

  .pop_tit {
    display: flex;
    min-height: 90px;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    border-bottom: solid 1px ${colors.divider01};
    padding: 0 34px 0 40px;

    span {
      font-size: 2.2rem;
      ${fontFamilyBold};
      color: ${colors.text02};
    }

    button {
      width: 30px;
      height: 30px;
      background-position: center center;
    }
  }

  .pop_body {
    height: calc(100% - 166px);

    &.pop_form {
      padding: 52px 62px 54px;

      .row {
        display: flex;
        align-items: center;
        gap: 23px;

        &:has(textarea), &:has(.img_w) {
          align-items: flex-start;

          .label {
            padding-top: 8px;
          }
        }

        .label {
          font-size: 1.5rem;
          min-width: 160px;
        }

        .input {
          width: 100%;

          > input {
            background: #fff;
            width: 100%;
            border: solid 1px ${colors.divider01};
            height: 40px;
            font-size: 1.5rem;
            color: #1A1D24;

            &:focus {
              border-color: ${colors.accent01};
            }

            &:disabled {
              background: ${colors.component03};
              border-color: ${colors.component04};
              color: rgba(141, 141, 141, 0.7);
            }

            &.input-error {
              border-color: ${colors.divider01};
              border-bottom-color: ${colors.fault};
              background-image: url("${AssetImages.common.input.inputError()}");
              background-repeat: no-repeat;
              background-position: right 10px center;
              padding-right: 40px;

              &:focus {
                background-image: none !important;
                border-color: ${colors.accent01};
                padding-right: 0;
              }
            }
          }

          .input-error-msg {
            font-size: 1.2rem;
            color: ${colors.fault};
            ${fontFamilyBold};
            margin-top: 4px;
          }

          textarea {
            background: #fff;
            width: 100%;
            border: solid 1px ${colors.divider01};
            min-height: 210px;
            font-size: 1.5rem;
            color: #1A1D24;
          }

          input, textarea {
            padding: 8px 12px;
            ${fontFamilyMedium};

            &::placeholder {
              font-size: 1.5rem;
              color: #7B8389;
              ${fontFamilyRegular};
            }
          }

          .img_w {
            width: 160px;
            height: 160px;
            border-radius: 5px;
            background: #EBEDF4;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        + .row {
          margin-top: 17px;
        }
      }
    }
  }

  .pop_btn {
    background: #fff;
    height: 76px;
    border-top: solid 1px ${colors.divider01};
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 16px;

    & button {
      min-width: 10rem;
      padding: 3px;
    }
  }

  @media screen and (max-width: 1199px) {
    max-height: 100%;
    max-width: 100% !important;
    width: 100% !important;
    height: 100%;
    outline: none;
    box-shadow: none;

    .pop_body {
      height: calc(100% - 162px);
      overflow-y: auto;

      &.pop_form {
        padding: 32px 40px;

        .row {
          align-items: flex-start !important;
          flex-direction: column;
          gap: 8px !important;

          .label {
            padding-top: 0 !important;
          }

          + .row {
            margin-top: 24px;
          }
        }
      }
    }

    .pop_btn {
      min-height: 72px;
      justify-content: center;
    }
  }
`;


export const AssetTabItem = styled.div`
  width: 100%;
  height: 100%;

  .react-tabs {
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }

  .react-tabs__tab-list {
    width: 100%;
    padding: 0 32px;
    background: ${colors.bg04};
    height: 64px;
    display: flex;
    gap: 52px;
  }

  .react-tabs__tab {
    height: 100%;
    line-height: 64px;
    text-align: center;
    list-style: none;
    cursor: pointer;
    position: relative;
    color: ${colors.text03};
    font-size: 1.8rem;
    padding: 0 4px;

    .badge {
      background: ${colors.text03};
    }
  }

  .react-tabs__tab--selected {
    color: ${colors.accent01};
    position: relative;

    ${fontFamilyBold}
    .badge {
      background: ${colors.accent01};
    }
  }

  .react-tabs__tab--selected:after {
    display: block;
    content: '';
    background: ${colors.accent01};
    width: 100%;
    height: .3rem;
    position: absolute;
    left: 0;
    bottom: 0;
    animation-duration: 0.3s;
    transform-origin: left;
    animation-name: slideright;
  }


  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab-panel {
    display: none;
  }

  .react-tabs__tab-panel--selected {
    display: block;
  }

  @media screen and (max-width: 1199px) {
    background: ${colors.bg04};
    .react-tabs__tab-list {
      height: 54px;
      padding: 0 16px;
      gap: 32px;
    }

    .react-tabs__tab {
      line-height: 54px;
      padding: 0;
      font-size: 1.4rem;

      .badge {
        height: 20px;
        line-height: 20px;
        font-size: 1.2rem;
        min-width: 26px;
      }
    }
  }

`;
export const TabTableBtn = styled.div`
  position: absolute;
  top: 16px;
  right: 24px;
  height: 32px;
  display: flex;
  align-items: center;


  .vertical_hr {
    display: inline-block;
    width: 1px;
    height: 15px;
    background: ${colors.divider01};
    margin: 0 22px;
  }

  button + button {
    margin-left: 22px;
  }

  @media screen and (max-width: 1199px) {
    top: 11px;
  }

`;
export const AssetTableWrap = styled.div`
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  position: relative;
  background: #fff;

  .table_box {
    padding-top: 40px;
    height: 100%;

    .fixed-table-header-bg {
      border-top: 1px solid ${colors.divider01};
      border-bottom: 1px solid ${colors.divider01};
      background: #EDF0F6;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
    }

    .fixed-table-wrapper {
      overflow-x: hidden;
      overflow-y: auto;
      height: 100%;
    }

    .th-text {
      position: absolute;
      top: 0;
      width: inherit;
      line-height: 38px;
      border-left: 1px solid ${colors.divider01};
      padding: 0 20px;
      color: ${colors.text03};
      ${fontFamilyRegular};
      background: #EDF0F6;
      margin: 1px 0;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;

    thead {
      th:first-child .th-text {
        border-left: none;
      }

      th.details-th .th-text {
        width: 110px;
        padding: 0 !important;
        text-align: center;
      }
    }

    &.click_table {
      tbody tr {
        cursor: pointer;

        &.tr_chk {
          background: ${colors.accent03};

          td {
            color: #000;
            ${fontFamilyMedium};

            button.toggleOn {
              background-color: ${colors.accent01};

              span {
                color: ${colors.accent01};
                ${fontFamilyMedium};
              }
            }

            .details_btn svg path {
              fill: #000;
            }
          }
        }
      }
    }

    &.over_table {
      td {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  tbody {
    tr {
      border-bottom: solid 1px #E6E6E6;

      td {
        height: 50px;
        padding: 0 20px;
        font-size: 1.5rem;
        ${fontFamilyRegular};

        &.center {
          text-align: center;
        }

        &.right {
          text-align: right;
        }

        &.td_icon {
          text-align: center;

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: ${colors.text07}
            }
          }
        }

        &.td_date {
          font-variant-numeric: tabular-nums;
          letter-spacing: -0.5px;
        }
      }

      &.tr_disabled td {
        color: rgba(0, 0, 0, 0.35);

        .size20 svg path {
          fill: ${colors.disconnected};
        }
      }

      &.tr_chk {
        border-color: #C0CEFF;

        td {
          border-top: solid 1px #C0CEFF;
        }
      }

      &.tr_empty {
        border-bottom: none;
        cursor: default !important;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        text-align: center;

        td {
          overflow: visible;
          height: 100% !important;
        }
      }
    }
  }

  .table_scroll {
    height: 100%;
  }

  &:has(.table_page) {
    .table_scroll {
      height: calc(100% - 60px);
    }
  }

  @media screen and (max-width: 1600px) {
    &.table_scroll_16 .table_scroll {
      overflow-x: auto;

      .table_box, .fixed-table-header-bg {
        min-width: 1600px;
      }

      .table_box {
        position: relative;
        height: 100%;
      }
    }
  }


  @media screen and (max-width: 1199px) {
    &:has(.table_page) {
      .table_scroll {
        height: calc(100% - 60px);
      }
    }

    thead {
      th.details-th .th-text {
        width: 80px !important;
        padding: 0 !important;
      }
    }

    .table_scroll {
      height: 100%;
      overflow: auto;

      .table_box {
        position: relative;
        height: 100%;
      }
    }
  }

`;
export const AssetTablePage = styled.div`
  border-top: solid 1px ${colors.divider01};
  height: 60px;
  line-height: 60px;
  text-align: center;

  > div {
    display: inline-flex;
    align-items: center;
    gap: 19px;
  }

  button {
    width: 14px;
    height: 14px;
    background-position: center center;

    &.prev, &.next {
      background-image: url("${AssetImages.common.icon.pagePrev()}");
    }

    &.first, &.last {
      background-image: url("${AssetImages.common.icon.pageFirst()}");
    }

    &.next, &.last {
      transform: scaleX(-1);
    }
  }

  .num_box {
    background: ${colors.accent03};
    border-radius: 15px;
    min-width: 90px;
    padding: 3px 15px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    font-size: 1.5rem;
    ${fontFamilyBold};
    color: ${colors.text03};

    span {
      line-height: 1;
    }

    span.now {
      margin-left: 3px;
    }
  }
`;
export const ValueCont = styled.div`
  display: flex;
  gap: 40px;

  p:not(.name) {
    font-size: 4.8rem;
    color: ${colors.accent01};

    span {
      font-size: 1.6rem;
      margin-left: 5px;
      color: ${colors.text07};
    }
  }

  p.name {
    position: relative;
  }
`;
export const InfoToolTipIcon = styled.span`
  display: inline-block;
  width: 16px !important;
  height: 16px !important;
  min-width: 16px;
  cursor: pointer;
  background: url(${AssetImages.common.icon.toolTip()});
  vertical-align: middle;
  background-position: center center;

  &.blue-icon {
    width: 24px !important;
    height: 24px !important;
    background: url(${AssetImages.common.icon.toolTipBu()});
  }
`;
export const MonitorCardWrap = styled.div`
  min-height: 237px;
  outline: solid 1px ${colors.component04};
  border-radius: 5px;
  box-shadow: 0 3px 4px 0 rgba(0, 41, 185, 0.1);
  overflow: hidden;
  position: relative;

  &:not(.divice_card) {
    cursor: pointer;
  }

  &.on, &:hover, &:active {
    &:not(.disconnected,.divice_card), &.divice_card.add_card {
      outline: solid 3px #0028B6;
    }
  }

  &.disconnected {
    background: rgba(255, 255, 255, 0.3) !important;
    outline-color: rgba(175, 186, 199, 0.3);

    .top_wrap .card_img, .state_cont {
      opacity: 0.3;
    }
  }

  &.add_card {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: solid 2px ${colors.divider01};
    background: #EBEDF4;
    box-shadow: none;

    &:after {
      display: inline-block;
      content: '';
      width: 84px;
      height: 84px;
      background: url("${AssetImages.monitor.projectPlusW}");
    }
  }

  .top_wrap {
    background: #F0F2F7;
    height: 96px;
    display: flex;

    .card_img {
      width: 96px;
      height: 96px;
      background: #E6EAF8;
      background-position: center center;
      background-size: cover;
    }

    .card_tit {
      width: calc(100% - 96px);
      padding: 20px 0 0 20px;
      position: relative;

      > a {
        display: inline-block;
        max-width: calc(100% - 48px);
      }

      .tit {
        font-size: 2rem;
        color: ${colors.text02};
        line-height: 24px;
        display: flex;
        align-items: center;


        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: calc(100% - 24px);
        }

        button.circle_arrow {
          margin-left: 4px;
        }
      }

      .txt {
        width: calc(100% - 60px);
        font-size: 1.5rem;
        color: ${colors.text04};
        line-height: 24px;
        margin-top: 2px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      button.more {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      .more_pop {
        right: 30px;
        top: 33px;
      }
    }
  }

  .cont_wrap {
    padding: 20px 20px 0 100px;

    .state_mark {
      position: absolute;
      left: 16px;
      bottom: 16px;
    }

    .state_cont {
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 32px;

        .name {
          font-size: 1.5rem;
          color: ${colors.text03};
          text-align: right;
          min-width: 108px;
        }

        p {
          font-size: 2.4rem;
          color: ${colors.text02};

          span {
            font-size: 1.2rem;
            ${fontFamilyRegular};
            color: #7B8389;
            vertical-align: middle;
            margin-left: 4px;
            width: 30px;
            display: inline-block;
          }
        }
      }
    }
  }
`;

export const TablePageContainer = styled.div`
  display: flex;
  height: calc(100vh - 76px);
  overflow: hidden;

  .react-tabs {
    height: 100%;

    .react-tabs__tab-panel {
      height: calc(100% - 64px);
    }
  }

  @media screen and (max-width: 1199px) {
    height: calc(100vh - 110px);
    .react-tabs__tab-panel {
      height: calc(100% - 54px) !important;
    }
  }
`;
export const CalendarType = styled.div`
  display: flex;
  align-items: center;
  @media screen and (min-width: 1200px) {
    margin-right: 22px;
    button {
      background: #fff;
      min-width: 49px;
      height: 32px;
      padding: 0 10px;
      text-align: center;
      line-height: 32px;
      border: solid 1px ${colors.divider01};
      font-size: 1.4rem;
      ${fontFamilyBold};
      color: ${colors.text03};

      + button {
        margin-left: -1px;
      }

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }

      &.custom {
        display: inline-flex;
        align-items: center;

        &:before {
          display: inline-block;
          content: '';
          width: 18px;
          height: 18px;
          background: url(${AssetImages.common.icon.date()}) left center no-repeat;
          margin-right: 3px;
          background-size: 18px;
        }
      }

      &.active {
        background: ${colors.accent03};
        border-color: ${colors.accent01};
        ${fontFamilyBold};
        color: ${colors.accent01};
        z-index: 2;
      }
    }
  }
`;
export const TitleCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background: ${colors.bg04};
  padding: 0 36px;

  .tit {
    font-size: 2rem;
    ${fontFamilyBold};
    color: ${colors.text02};

    a {
      color: ${colors.text02};
    }
  }

  @media screen and (max-width: 1199px) {
    height: 64px;
    padding: 0 32px;
  }
`;
export const BarGraph = styled.div`
  width: 100%;

  .graph {
    background: rgba(141, 141, 141, 0.1);
    height: 16px;
    width: 100%;
    margin-bottom: 8px;

    .bg {
      display: inline-block;
      height: 16px;
      background: ${colors.normal};
      animation: bgFill 1.5s forwards;

      &.normal {
        background: ${colors.normal};
      }

      &.warning {
        background: ${colors.warning};
      }

      &.fault {
        background: ${colors.fault};
      }
    }
  }

  @keyframes bgFill {
    from {
      width: 0;
    }
  }

  .graph_txt {
    font-size: 1.8rem;
    color: ${colors.text03};
    display: flex;
    justify-content: space-between;

    .name {
      span.state_mark {
        margin-left: 12px;
      }
    }

    span.unit {
      font-size: 1.5rem;
      color: ${colors.text07};
      margin-left: 3px;
    }
  }
`;

export const WeatherWrap = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #7B8389;
  gap: 16px;

  .we_now {
    display: flex;
    align-items: center;

    ${fontFamilyRegular}
    .temp {
      margin-left: 8px;
      margin-right: 12px;

      ${fontFamilyMedium}
      span {
        font-size: 2rem;
        color: ${colors.text02}
      }
    }
  }

  .we_info {
    p {
      ${fontFamilyRegular};
      display: inline-flex;
      align-items: center;
      gap: 6px;

      + p {
        margin-left: 12px
      }
    }
  }

  @media screen and (max-width: 1199px) {
    display: none !important;
  }
`;
export const SelectList = styled.div`
  min-width: 210px;
  width: 100%;
  height: 240px;
  background: #fff;
  border: Solid 1px ${colors.divider06};
  box-shadow: 1px 2px 2px 0px rgba(46, 72, 144, 0.8);
  position: absolute;
  top: calc(100% + 9px);
  z-index: 9;
  overflow: hidden;

  .search_input {
    position: relative;
    height: 46px;
    padding: 4px;
    border-bottom: solid 1px ${colors.divider01};

    input {
      background: ${colors.component02};
      height: 37px;
      padding: 0 35px 0 10px !important;
    }

    .search_input_btn {
      right: 13px
    }
  }

  .select_list {
    height: calc(100% - 46px);
    overflow-y: auto;

    > div {
      padding: 10px 15px;
      height: 38px;
    }
  }

  .tran-overlay {
    z-index: -1;
  }
`;
export const MobileSelectList = styled.div`
  position: fixed;
  z-index: 101;
  display: none;
  width: 21.6rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: solid 1px ${colors.divider06};
  max-height: 26rem;
  overflow: hidden auto;

  button {
    width: 100%;
    line-height: 5.2rem;
    font-size: 1.6rem;
    user-select: none;
    cursor: pointer;
    margin: 0 !important;
    color: ${colors.text03};
    text-align: left;
    padding: 0 12px;

    ${fontFamilyRegular};
  }

  &.on {
    display: block;
  }

  @media screen and (max-width: 1199px) {
    &.mobile:not(.on) {
      display: none;
    }
  }
`;
export const TableFilterWrap = styled(SelectList)`
  width: 100%;
  max-width: 300px;
  height: auto;
  position: absolute;
  top: 40px;
  right: 0;

  &:has(.search_input) {
    max-height: 238px;

    .select_list {
      height: auto;
      max-height: calc(238px - 46px);
    }
  }

  &:not(:has(.search_input)) {
    overflow-y: auto;
    max-height: 192px;
  }

  .select_list {
    ${fontFamilyRegular};
  }
`;
export const AssetFilterTableWrap = styled(AssetTableWrap)`
  .table_box {
    padding-top: 0;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  th {
    position: relative;
    text-align: left;
    height: 40px;

    &:first-child {
      .drop-list-wrap {
        left: 0;
        right: auto
      }
    }

    &:hover {
      .th-text {
        background: ${colors.accent03};
        color: ${colors.accent01};
        ${fontFamilyMedium};
        height: 38px;
        line-height: 38px;
        background: ${colors.accent03};
        margin: 1px 0;

        .drop_arrow {
          display: inline-block !important;
          border: solid 1px ${colors.accent01};
        }
      }

      &:not(.filter_th) {
        .th-text {
          width: 100%
        }
      }
    }

    &:has(.filter_sort) {
      cursor: pointer;
    }

    .filter_sort {
      margin-left: 8px;
      width: 14px;
      height: 14px;

      img {
        vertical-align: middle;

        &.asc_icon {
          transform: scaleY(-1);
        }
      }
    }

    &.filter_th {
      .th-text {
        cursor: pointer;
        padding-right: 0;
        width: 100%;

        > p {
          display: inline-flex;
          align-items: center;
          width: 100%;

          .filter_sort {
            display: inline-flex;
            align-items: center;
          }

          &:not([class]) {
            .filter_sort {
              display: none;
              background: url(${AssetImages.common.icon.filterSort()});
            }

            &:hover {
              .filter_sort {
                display: block;
              }
            }
          }
        }

        .drop_arrow {
          display: none;
          content: '';
          width: 26px;
          height: 40px;
          top: -1px;
          position: absolute;
          right: 0;
          background-image: url("${AssetImages.common.icon.filterArrow()}");
          background-size: 14px auto;
          background-repeat: no-repeat;
          background-position: center center;
        }

        &:hover {
          p.initial .filter_sort img {
            display: block;
          }
        }
      }

      &.open {
        .th-text {
          background: ${colors.accent03};
          color: ${colors.accent01};
          ${fontFamilyMedium};
        }

        .drop_arrow {
          transform: scaleY(-1);
        }
      }
    }


    &.filter_set, &.open {
      .th-text .drop_arrow {
        display: inline-block;
        border: solid 1px ${colors.accent01};
      }
    }
  }
`;
export const ErrorDataWrap = styled.div`
  font-size: 2rem;
  ${fontFamilyMedium};
  color: ${colors.text03};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:before {
    display: inline-block;
    content: '';
    width: 120px;
    height: 120px;
    background: url(${AssetImages.common.icon.nodata()});
    margin-bottom: 8px;
  }

  button {
    margin-top: 24px;
  }
`;
