import {isVal} from "util/ssqutil";
import {DEV_STAT, STACD_DIR, STATCD_SPEED} from "asset/models/defs";
import {AssetImages} from "asset/images/AssetImages";
import {i18ntrans} from "language/i18nkey";

export const AMCHART_LICENSE = 'CH170099773';
export const AMCHART_MAP_LICENSE = 'MP170099773';

export const nulllist = [];
export const nullobj: any = {};

export const zeroToDash = (val: number) => val === 0 ? '-' : val;


export const comma = (n: number): string => {
  if (n === null || n === undefined) return '';
  return n.toLocaleString(undefined, {maximumFractionDigits: 5});
};

export const commaDef = (n: number, def: string = ''): string => {
  if (n === null || n === undefined) return def;
  return n.toLocaleString(undefined, {maximumFractionDigits: 5});
};

export const getIcon = (status: DEV_STAT) => {
  if (status === "DISCON") return "disconnected";
  if (status === "FAULT") return "fault";
  if (status === "WARN") return "warning";
  if (status === "NORMAL") return "normal";
  return "disconnected";
};
export const getStatusText = (status: DEV_STAT) => {
  if (status === "DISCON") return "Disconnected";
  if (status === "FAULT") return "Fault";
  if (status === "WARN") return "Warning";
  if (status === "NORMAL") return "Normal";
  return "Disconnected";
};

export const dispVal = (n: number, def: string = '', fixed?: number): string => {
  if (n === null || n === undefined) return def;
  if (isVal(fixed)) return n.toFixed(fixed);
  return n.toString();
};

export const driveRunStop = (n: STACD_DIR) => {
  if (n === STACD_DIR.Stopped) return "Stop";
  return "Run";
};

export const directionName = (n: STACD_DIR) => {
  const t = i18ntrans.tasset;
  if (n === STACD_DIR.Stopped) return t('I18N_AST_DIR_STOP');
  if (n === STACD_DIR.DC_operating) return t('I18N_AST_DIR_DC_OPER');
  if (n === STACD_DIR.Operating_in_reverse_direction) return "Reverse";
  if (n === STACD_DIR.Operating_in_forward_direction) return "Forward";
  return '-';
};

export const directionNameIcon = (n: STACD_DIR) => {
  if (n === STACD_DIR.Stopped) return AssetImages.monitor.stop();
  if (n === STACD_DIR.DC_operating) return AssetImages.monitor.forward();
  if (n === STACD_DIR.Operating_in_reverse_direction) return AssetImages.monitor.backward();
  if (n === STACD_DIR.Operating_in_forward_direction) return AssetImages.monitor.forward();
  return AssetImages.monitor.forward(); // default
};

export const speedName = (n: STATCD_SPEED) => {
  const t = i18ntrans.tasset;
  if (n === STATCD_SPEED.Accelerating) return t('I18N_AST_SPEED_ACCELERATING');
  if (n === STATCD_SPEED.HW_OCS) return t('I18N_AST_SPEED_HW_OCS');
  if (n === STATCD_SPEED.SW_OCS) return t('I18N_AST_SPEED_SW_OCS');
  if (n === STATCD_SPEED.Decelerating) return t('I18N_AST_SPEED_DECELERATING');
  if (n === STATCD_SPEED.Speed_Searching) return t('I18N_AST_SPEED_SPEED_SEARCHING');
  if (n === STATCD_SPEED.Decelerating_to_stop) return t('I18N_AST_SPEED_DECELERATING_TO_STOP');
  if (n === STATCD_SPEED.Operating_at_constant_rate) return t('I18N_AST_SPEED_OPERATING_AT_CONSTANT_RATE');
  if (n === STATCD_SPEED.Dwell_operating) return t('I18N_AST_SPEED_DWELL_OPERATING');
  return '-';
};

export const speedNameIcon = (n: STATCD_SPEED) => {
  if (n === STATCD_SPEED.Accelerating) return AssetImages.monitor.acceleration();
  if (n === STATCD_SPEED.HW_OCS) return AssetImages.monitor.warning();
  if (n === STATCD_SPEED.SW_OCS) return AssetImages.monitor.warning();
  if (n === STATCD_SPEED.Decelerating) return AssetImages.monitor.deceleration();
  if (n === STATCD_SPEED.Speed_Searching) return AssetImages.monitor.search();
  if (n === STATCD_SPEED.Decelerating_to_stop) return AssetImages.monitor.stop();
  if (n === STATCD_SPEED.Operating_at_constant_rate) return AssetImages.monitor.constant();
  if (n === STATCD_SPEED.Dwell_operating) return AssetImages.monitor.dwell();

  return AssetImages.monitor.acceleration();
};

export const addDays = (d: Date, days: number): Date => {
  const nd = new Date(d.valueOf());
  nd.setDate(nd.getDate() + days);
  return nd;
};

export function addHours(date: Date, hours: number) {
  const hoursToAdd = hours * 60 * 60 * 1000;
  const nd = new Date(date.valueOf());
  nd.setTime(date.getTime() + hoursToAdd);
  return nd;
}

export function floorToHour(date: Date) {
  const hour = 60 * 60 * 1000;
  const nd = new Date(date.valueOf());
  nd.setTime(Math.floor(date.getTime() / hour) * hour);
  return nd;
}

export const getCapPercentClass = (percentage: number) => {
  if (!isVal(percentage)) {
    // const a = ['normal', 'warning', 'warning'];
    // return a[Math.floor(Math.random() * 3)];
    return '';
  }
  if (percentage < 70) return 'normal';
  //if (percentage >= 50) return 'warning';
  //return 'fault';
  return 'warning';
};

export const getCapStatusText = (percentage: number): string => {
  if (!isVal(percentage)) {
    // const a = ['normal', 'warning', 'warning'];
    // return a[Math.floor(Math.random() * 3)];
    return '';
  }
  const t = i18ntrans.tasset;
  if (percentage < 70) return t('I18N_AST_MONITOR_CAP_NORMAL');
  //if (percentage >= 50) return 'warning';
  //return 'fault';
  return t('I18N_AST_MONITOR_CAP_WARN');
};

export const getCapPercentStr = (percent: number) => {
  if (!isVal(percent)) {
    //return Math.floor(Math.random() * 95 + 5) + '%';
    return '0%';
  }
  return '' + percent + '%';
};

export const getFanPercentClass = (percentage: number) => {
  if (!isVal(percentage)) {
    // const a = ['normal', 'warning', 'warning'];
    // return a[Math.floor(Math.random() * 3)];
    return '';
  }
  if (percentage < 90) return 'normal';
  //if (percentage >= 50) return 'warning';
  return "warning";
  //return 'fault';
};

export const getFanStatusText = (percentage: number) => {
  if (!isVal(percentage)) {
    // const a = ['normal', 'warning', 'warning'];
    // return a[Math.floor(Math.random() * 3)];
    return '';
  }
  const t = i18ntrans.tasset;
  if (percentage < 90) return t('I18N_AST_MONITOR_CAP_NORMAL');
  //if (percentage >= 50) return 'warning';
  return t('I18N_AST_MONITOR_CAP_WARN');
  //return 'fault';
};


export const getFanPercentStr = (percent: number) => {
  if (!isVal(percent)) {
    //return Math.floor(Math.random() * 95 + 5) + '%';
    return '0%';
  }
  return '' + percent + '%';
};

export const getPercentClass = (percentage: number) => {
  if (!isVal(percentage)) {
    // const a = ['normal', 'warning', 'warning'];
    // return a[Math.floor(Math.random() * 3)];
    return '';
  }
  if (percentage >= 70) return 'normal';
  if (percentage >= 50) return 'warning';
  return 'fault';
};

export const getPercentStr = (percent: number) => {
  if (!isVal(percent)) {
    //return Math.floor(Math.random() * 95 + 5) + '%';
    return '0%';
  }
  return '' + percent + '%';
};

export const convertMinuteToDateString = (minutes) => {
  const days = Math.floor(minutes / (60 * 24));
  const hours = Math.floor(minutes % (60 * 24) / 60);
  const finalMinutes = minutes % 60;

  return `${days}D ${String(hours).padStart(2, '0')}:${String(finalMinutes).padStart(2, '0')}`;
};